// source: mobile.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var commUnity_pb = require('./commUnity_pb.js');
goog.object.extend(proto, commUnity_pb);
var forum_pb = require('./forum_pb.js');
goog.object.extend(proto, forum_pb);
var shop_pb = require('./shop_pb.js');
goog.object.extend(proto, shop_pb);
var restaurant_pb = require('./restaurant_pb.js');
goog.object.extend(proto, restaurant_pb);
goog.exportSymbol('proto.commUnity.CPSURL', null, global);
goog.exportSymbol('proto.commUnity.CustomerMobile', null, global);
goog.exportSymbol('proto.commUnity.DeliveryCost', null, global);
goog.exportSymbol('proto.commUnity.EventMobile', null, global);
goog.exportSymbol('proto.commUnity.FCMToken', null, global);
goog.exportSymbol('proto.commUnity.GroupCustomers', null, global);
goog.exportSymbol('proto.commUnity.GuestTextRequest', null, global);
goog.exportSymbol('proto.commUnity.MemberProfileRequest', null, global);
goog.exportSymbol('proto.commUnity.MemberToken', null, global);
goog.exportSymbol('proto.commUnity.MinVersion', null, global);
goog.exportSymbol('proto.commUnity.MobApp', null, global);
goog.exportSymbol('proto.commUnity.ProductToRemove', null, global);
goog.exportSymbol('proto.commUnity.ProductToRemoveFromMeal', null, global);
goog.exportSymbol('proto.commUnity.RequestCustomer', null, global);
goog.exportSymbol('proto.commUnity.RequestCustomer.QueryCase', null, global);
goog.exportSymbol('proto.commUnity.RequestPassword', null, global);
goog.exportSymbol('proto.commUnity.RequestPassword.UseremailchoiceCase', null, global);
goog.exportSymbol('proto.commUnity.SearchRequest', null, global);
goog.exportSymbol('proto.commUnity.SearchResult', null, global);
goog.exportSymbol('proto.commUnity.SearchResult.ResultCase', null, global);
goog.exportSymbol('proto.commUnity.SignInMobileRequest', null, global);
goog.exportSymbol('proto.commUnity.SignUpMobileRequest', null, global);
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ProductToRemove = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.ProductToRemove, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ProductToRemove.displayName = 'proto.commUnity.ProductToRemove';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.ProductToRemoveFromMeal = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.ProductToRemoveFromMeal, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.ProductToRemoveFromMeal.displayName = 'proto.commUnity.ProductToRemoveFromMeal';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RequestCustomer = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.commUnity.RequestCustomer.oneofGroups_);
};
goog.inherits(proto.commUnity.RequestCustomer, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RequestCustomer.displayName = 'proto.commUnity.RequestCustomer';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CustomerMobile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.CustomerMobile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CustomerMobile.displayName = 'proto.commUnity.CustomerMobile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.GroupCustomers = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.GroupCustomers.repeatedFields_, null);
};
goog.inherits(proto.commUnity.GroupCustomers, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.GroupCustomers.displayName = 'proto.commUnity.GroupCustomers';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.GuestTextRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.GuestTextRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.GuestTextRequest.displayName = 'proto.commUnity.GuestTextRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MobApp = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.MobApp, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MobApp.displayName = 'proto.commUnity.MobApp';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.DeliveryCost = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.DeliveryCost, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.DeliveryCost.displayName = 'proto.commUnity.DeliveryCost';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.FCMToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.FCMToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.FCMToken.displayName = 'proto.commUnity.FCMToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.SignUpMobileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.SignUpMobileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.SignUpMobileRequest.displayName = 'proto.commUnity.SignUpMobileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MemberToken = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.MemberToken, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MemberToken.displayName = 'proto.commUnity.MemberToken';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MinVersion = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.MinVersion, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MinVersion.displayName = 'proto.commUnity.MinVersion';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.SearchResult = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.commUnity.SearchResult.oneofGroups_);
};
goog.inherits(proto.commUnity.SearchResult, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.SearchResult.displayName = 'proto.commUnity.SearchResult';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.SearchRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.SearchRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.SearchRequest.displayName = 'proto.commUnity.SearchRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.MemberProfileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.commUnity.MemberProfileRequest.repeatedFields_, null);
};
goog.inherits(proto.commUnity.MemberProfileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.MemberProfileRequest.displayName = 'proto.commUnity.MemberProfileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.EventMobile = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.EventMobile, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.EventMobile.displayName = 'proto.commUnity.EventMobile';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.SignInMobileRequest = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.SignInMobileRequest, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.SignInMobileRequest.displayName = 'proto.commUnity.SignInMobileRequest';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.RequestPassword = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.commUnity.RequestPassword.oneofGroups_);
};
goog.inherits(proto.commUnity.RequestPassword, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.RequestPassword.displayName = 'proto.commUnity.RequestPassword';
}
/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.commUnity.CPSURL = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.commUnity.CPSURL, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  /**
   * @public
   * @override
   */
  proto.commUnity.CPSURL.displayName = 'proto.commUnity.CPSURL';
}



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ProductToRemove.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ProductToRemove.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ProductToRemove} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductToRemove.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    size: jspb.Message.getFieldWithDefault(msg, 2, ""),
    color: jspb.Message.getFieldWithDefault(msg, 3, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ProductToRemove}
 */
proto.commUnity.ProductToRemove.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ProductToRemove;
  return proto.commUnity.ProductToRemove.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ProductToRemove} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ProductToRemove}
 */
proto.commUnity.ProductToRemove.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setSize(value);
      break;
    case 3:
      var value = /** @type {!proto.commUnity.Color} */ (reader.readEnum());
      msg.setColor(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ProductToRemove.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ProductToRemove.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ProductToRemove} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductToRemove.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getSize();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getColor();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
};


/**
 * optional string ProductID = 1;
 * @return {string}
 */
proto.commUnity.ProductToRemove.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductToRemove} returns this
 */
proto.commUnity.ProductToRemove.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Size = 2;
 * @return {string}
 */
proto.commUnity.ProductToRemove.prototype.getSize = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductToRemove} returns this
 */
proto.commUnity.ProductToRemove.prototype.setSize = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional Color Color = 3;
 * @return {!proto.commUnity.Color}
 */
proto.commUnity.ProductToRemove.prototype.getColor = function() {
  return /** @type {!proto.commUnity.Color} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.commUnity.Color} value
 * @return {!proto.commUnity.ProductToRemove} returns this
 */
proto.commUnity.ProductToRemove.prototype.setColor = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.ProductToRemoveFromMeal.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.ProductToRemoveFromMeal.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.ProductToRemoveFromMeal} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductToRemoveFromMeal.toObject = function(includeInstance, msg) {
  var f, obj = {
    productid: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mealid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.ProductToRemoveFromMeal}
 */
proto.commUnity.ProductToRemoveFromMeal.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.ProductToRemoveFromMeal;
  return proto.commUnity.ProductToRemoveFromMeal.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.ProductToRemoveFromMeal} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.ProductToRemoveFromMeal}
 */
proto.commUnity.ProductToRemoveFromMeal.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setProductid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setMealid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.ProductToRemoveFromMeal.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.ProductToRemoveFromMeal.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.ProductToRemoveFromMeal} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.ProductToRemoveFromMeal.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getProductid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getMealid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string ProductID = 1;
 * @return {string}
 */
proto.commUnity.ProductToRemoveFromMeal.prototype.getProductid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductToRemoveFromMeal} returns this
 */
proto.commUnity.ProductToRemoveFromMeal.prototype.setProductid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string MealID = 2;
 * @return {string}
 */
proto.commUnity.ProductToRemoveFromMeal.prototype.getMealid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.ProductToRemoveFromMeal} returns this
 */
proto.commUnity.ProductToRemoveFromMeal.prototype.setMealid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.commUnity.RequestCustomer.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.commUnity.RequestCustomer.QueryCase = {
  QUERY_NOT_SET: 0,
  ID: 1,
  MOBILEAPP: 2
};

/**
 * @return {proto.commUnity.RequestCustomer.QueryCase}
 */
proto.commUnity.RequestCustomer.prototype.getQueryCase = function() {
  return /** @type {proto.commUnity.RequestCustomer.QueryCase} */(jspb.Message.computeOneofCase(this, proto.commUnity.RequestCustomer.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RequestCustomer.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RequestCustomer.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RequestCustomer} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestCustomer.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    mobileapp: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RequestCustomer}
 */
proto.commUnity.RequestCustomer.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RequestCustomer;
  return proto.commUnity.RequestCustomer.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RequestCustomer} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RequestCustomer}
 */
proto.commUnity.RequestCustomer.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RequestCustomer.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RequestCustomer.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RequestCustomer} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestCustomer.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.RequestCustomer.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestCustomer} returns this
 */
proto.commUnity.RequestCustomer.prototype.setId = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.commUnity.RequestCustomer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.commUnity.RequestCustomer} returns this
 */
proto.commUnity.RequestCustomer.prototype.clearId = function() {
  return jspb.Message.setOneofField(this, 1, proto.commUnity.RequestCustomer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.RequestCustomer.prototype.hasId = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional MobileApp MobileApp = 2;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.RequestCustomer.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.RequestCustomer} returns this
 */
proto.commUnity.RequestCustomer.prototype.setMobileapp = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.commUnity.RequestCustomer.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.commUnity.RequestCustomer} returns this
 */
proto.commUnity.RequestCustomer.prototype.clearMobileapp = function() {
  return jspb.Message.setOneofField(this, 2, proto.commUnity.RequestCustomer.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.RequestCustomer.prototype.hasMobileapp = function() {
  return jspb.Message.getField(this, 2) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CustomerMobile.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CustomerMobile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CustomerMobile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CustomerMobile.toObject = function(includeInstance, msg) {
  var f, obj = {
    id: jspb.Message.getFieldWithDefault(msg, 1, ""),
    name: jspb.Message.getFieldWithDefault(msg, 2, ""),
    logo: jspb.Message.getFieldWithDefault(msg, 3, ""),
    category: jspb.Message.getFieldWithDefault(msg, 4, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CustomerMobile}
 */
proto.commUnity.CustomerMobile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CustomerMobile;
  return proto.commUnity.CustomerMobile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CustomerMobile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CustomerMobile}
 */
proto.commUnity.CustomerMobile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setId(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setName(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setLogo(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCategory(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CustomerMobile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CustomerMobile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CustomerMobile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CustomerMobile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getId();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getName();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getLogo();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getCategory();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
};


/**
 * optional string ID = 1;
 * @return {string}
 */
proto.commUnity.CustomerMobile.prototype.getId = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomerMobile} returns this
 */
proto.commUnity.CustomerMobile.prototype.setId = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Name = 2;
 * @return {string}
 */
proto.commUnity.CustomerMobile.prototype.getName = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomerMobile} returns this
 */
proto.commUnity.CustomerMobile.prototype.setName = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Logo = 3;
 * @return {string}
 */
proto.commUnity.CustomerMobile.prototype.getLogo = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomerMobile} returns this
 */
proto.commUnity.CustomerMobile.prototype.setLogo = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional string Category = 4;
 * @return {string}
 */
proto.commUnity.CustomerMobile.prototype.getCategory = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CustomerMobile} returns this
 */
proto.commUnity.CustomerMobile.prototype.setCategory = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.GroupCustomers.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.GroupCustomers.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.GroupCustomers.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.GroupCustomers} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GroupCustomers.toObject = function(includeInstance, msg) {
  var f, obj = {
    customersList: jspb.Message.toObjectList(msg.getCustomersList(),
    proto.commUnity.CustomerMobile.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.GroupCustomers}
 */
proto.commUnity.GroupCustomers.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.GroupCustomers;
  return proto.commUnity.GroupCustomers.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.GroupCustomers} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.GroupCustomers}
 */
proto.commUnity.GroupCustomers.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.commUnity.CustomerMobile;
      reader.readMessage(value,proto.commUnity.CustomerMobile.deserializeBinaryFromReader);
      msg.addCustomers(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.GroupCustomers.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.GroupCustomers.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.GroupCustomers} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GroupCustomers.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getCustomersList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.commUnity.CustomerMobile.serializeBinaryToWriter
    );
  }
};


/**
 * repeated CustomerMobile Customers = 1;
 * @return {!Array<!proto.commUnity.CustomerMobile>}
 */
proto.commUnity.GroupCustomers.prototype.getCustomersList = function() {
  return /** @type{!Array<!proto.commUnity.CustomerMobile>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.commUnity.CustomerMobile, 1));
};


/**
 * @param {!Array<!proto.commUnity.CustomerMobile>} value
 * @return {!proto.commUnity.GroupCustomers} returns this
*/
proto.commUnity.GroupCustomers.prototype.setCustomersList = function(value) {
  return jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.commUnity.CustomerMobile=} opt_value
 * @param {number=} opt_index
 * @return {!proto.commUnity.CustomerMobile}
 */
proto.commUnity.GroupCustomers.prototype.addCustomers = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.commUnity.CustomerMobile, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.GroupCustomers} returns this
 */
proto.commUnity.GroupCustomers.prototype.clearCustomersList = function() {
  return this.setCustomersList([]);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.GuestTextRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.GuestTextRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.GuestTextRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GuestTextRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobileapp: jspb.Message.getFieldWithDefault(msg, 1, 0),
    customerid: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.GuestTextRequest}
 */
proto.commUnity.GuestTextRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.GuestTextRequest;
  return proto.commUnity.GuestTextRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.GuestTextRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.GuestTextRequest}
 */
proto.commUnity.GuestTextRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.GuestTextRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.GuestTextRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.GuestTextRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.GuestTextRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional MobileApp MobileApp = 1;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.GuestTextRequest.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.GuestTextRequest} returns this
 */
proto.commUnity.GuestTextRequest.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};


/**
 * optional string CustomerID = 2;
 * @return {string}
 */
proto.commUnity.GuestTextRequest.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.GuestTextRequest} returns this
 */
proto.commUnity.GuestTextRequest.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MobApp.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MobApp.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MobApp} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MobApp.toObject = function(includeInstance, msg) {
  var f, obj = {
    mobileapp: jspb.Message.getFieldWithDefault(msg, 1, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MobApp}
 */
proto.commUnity.MobApp.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MobApp;
  return proto.commUnity.MobApp.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MobApp} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MobApp}
 */
proto.commUnity.MobApp.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MobApp.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MobApp.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MobApp} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MobApp.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      1,
      f
    );
  }
};


/**
 * optional MobileApp MobileApp = 1;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.MobApp.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 1, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.MobApp} returns this
 */
proto.commUnity.MobApp.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.DeliveryCost.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.DeliveryCost.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.DeliveryCost} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.DeliveryCost.toObject = function(includeInstance, msg) {
  var f, obj = {
    deliverycost: jspb.Message.getFloatingPointFieldWithDefault(msg, 1, 0.0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.DeliveryCost}
 */
proto.commUnity.DeliveryCost.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.DeliveryCost;
  return proto.commUnity.DeliveryCost.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.DeliveryCost} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.DeliveryCost}
 */
proto.commUnity.DeliveryCost.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setDeliverycost(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.DeliveryCost.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.DeliveryCost.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.DeliveryCost} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.DeliveryCost.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getDeliverycost();
  if (f !== 0.0) {
    writer.writeFloat(
      1,
      f
    );
  }
};


/**
 * optional float DeliveryCost = 1;
 * @return {number}
 */
proto.commUnity.DeliveryCost.prototype.getDeliverycost = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 1, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.DeliveryCost} returns this
 */
proto.commUnity.DeliveryCost.prototype.setDeliverycost = function(value) {
  return jspb.Message.setProto3FloatField(this, 1, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.FCMToken.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.FCMToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.FCMToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.FCMToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    osmobile: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.FCMToken}
 */
proto.commUnity.FCMToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.FCMToken;
  return proto.commUnity.FCMToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.FCMToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.FCMToken}
 */
proto.commUnity.FCMToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = /** @type {!proto.commUnity.OS} */ (reader.readEnum());
      msg.setOsmobile(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.FCMToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.FCMToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.FCMToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.FCMToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getOsmobile();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.commUnity.FCMToken.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.FCMToken} returns this
 */
proto.commUnity.FCMToken.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional OS OSMobile = 2;
 * @return {!proto.commUnity.OS}
 */
proto.commUnity.FCMToken.prototype.getOsmobile = function() {
  return /** @type {!proto.commUnity.OS} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commUnity.OS} value
 * @return {!proto.commUnity.FCMToken} returns this
 */
proto.commUnity.FCMToken.prototype.setOsmobile = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.SignUpMobileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.SignUpMobileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.SignUpMobileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SignUpMobileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    lastname: jspb.Message.getFieldWithDefault(msg, 1, ""),
    firstname: jspb.Message.getFieldWithDefault(msg, 2, ""),
    email: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mobileapp: jspb.Message.getFieldWithDefault(msg, 4, 0),
    mobile: jspb.Message.getFieldWithDefault(msg, 5, ""),
    customerid: jspb.Message.getFieldWithDefault(msg, 6, ""),
    username: jspb.Message.getFieldWithDefault(msg, 7, ""),
    google: jspb.Message.getBooleanFieldWithDefault(msg, 10, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.SignUpMobileRequest}
 */
proto.commUnity.SignUpMobileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.SignUpMobileRequest;
  return proto.commUnity.SignUpMobileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.SignUpMobileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.SignUpMobileRequest}
 */
proto.commUnity.SignUpMobileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setLastname(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirstname(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 4:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setMobile(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 10:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setGoogle(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.SignUpMobileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.SignUpMobileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.SignUpMobileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SignUpMobileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getLastname();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getFirstname();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getEmail();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      4,
      f
    );
  }
  f = message.getMobile();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGoogle();
  if (f) {
    writer.writeBool(
      10,
      f
    );
  }
};


/**
 * optional string LastName = 1;
 * @return {string}
 */
proto.commUnity.SignUpMobileRequest.prototype.getLastname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setLastname = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string FirstName = 2;
 * @return {string}
 */
proto.commUnity.SignUpMobileRequest.prototype.getFirstname = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setFirstname = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string Email = 3;
 * @return {string}
 */
proto.commUnity.SignUpMobileRequest.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setEmail = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MobileApp MobileApp = 4;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.SignUpMobileRequest.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 4, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 4, value);
};


/**
 * optional string Mobile = 5;
 * @return {string}
 */
proto.commUnity.SignUpMobileRequest.prototype.getMobile = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setMobile = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};


/**
 * optional string CustomerID = 6;
 * @return {string}
 */
proto.commUnity.SignUpMobileRequest.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string UserName = 7;
 * @return {string}
 */
proto.commUnity.SignUpMobileRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bool Google = 10;
 * @return {boolean}
 */
proto.commUnity.SignUpMobileRequest.prototype.getGoogle = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 10, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.SignUpMobileRequest} returns this
 */
proto.commUnity.SignUpMobileRequest.prototype.setGoogle = function(value) {
  return jspb.Message.setProto3BooleanField(this, 10, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MemberToken.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MemberToken.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MemberToken} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberToken.toObject = function(includeInstance, msg) {
  var f, obj = {
    token: jspb.Message.getFieldWithDefault(msg, 1, ""),
    profile: (f = msg.getProfile()) && commUnity_pb.Member.toObject(includeInstance, f),
    needfcmtoken: jspb.Message.getBooleanFieldWithDefault(msg, 3, false),
    customer: (f = msg.getCustomer()) && commUnity_pb.Customer.toObject(includeInstance, f),
    mobileapp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    group: (f = msg.getGroup()) && commUnity_pb.Group.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MemberToken}
 */
proto.commUnity.MemberToken.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MemberToken;
  return proto.commUnity.MemberToken.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MemberToken} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MemberToken}
 */
proto.commUnity.MemberToken.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setToken(value);
      break;
    case 2:
      var value = new commUnity_pb.Member;
      reader.readMessage(value,commUnity_pb.Member.deserializeBinaryFromReader);
      msg.setProfile(value);
      break;
    case 3:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setNeedfcmtoken(value);
      break;
    case 4:
      var value = new commUnity_pb.Customer;
      reader.readMessage(value,commUnity_pb.Customer.deserializeBinaryFromReader);
      msg.setCustomer(value);
      break;
    case 5:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    case 10:
      var value = new commUnity_pb.Group;
      reader.readMessage(value,commUnity_pb.Group.deserializeBinaryFromReader);
      msg.setGroup(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MemberToken.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MemberToken.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MemberToken} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberToken.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getToken();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getProfile();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commUnity_pb.Member.serializeBinaryToWriter
    );
  }
  f = message.getNeedfcmtoken();
  if (f) {
    writer.writeBool(
      3,
      f
    );
  }
  f = message.getCustomer();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      commUnity_pb.Customer.serializeBinaryToWriter
    );
  }
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getGroup();
  if (f != null) {
    writer.writeMessage(
      10,
      f,
      commUnity_pb.Group.serializeBinaryToWriter
    );
  }
};


/**
 * optional string token = 1;
 * @return {string}
 */
proto.commUnity.MemberToken.prototype.getToken = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberToken} returns this
 */
proto.commUnity.MemberToken.prototype.setToken = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional Member profile = 2;
 * @return {?proto.commUnity.Member}
 */
proto.commUnity.MemberToken.prototype.getProfile = function() {
  return /** @type{?proto.commUnity.Member} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Member, 2));
};


/**
 * @param {?proto.commUnity.Member|undefined} value
 * @return {!proto.commUnity.MemberToken} returns this
*/
proto.commUnity.MemberToken.prototype.setProfile = function(value) {
  return jspb.Message.setWrapperField(this, 2, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.MemberToken} returns this
 */
proto.commUnity.MemberToken.prototype.clearProfile = function() {
  return this.setProfile(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.MemberToken.prototype.hasProfile = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional bool needFCMToken = 3;
 * @return {boolean}
 */
proto.commUnity.MemberToken.prototype.getNeedfcmtoken = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 3, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberToken} returns this
 */
proto.commUnity.MemberToken.prototype.setNeedfcmtoken = function(value) {
  return jspb.Message.setProto3BooleanField(this, 3, value);
};


/**
 * optional Customer Customer = 4;
 * @return {?proto.commUnity.Customer}
 */
proto.commUnity.MemberToken.prototype.getCustomer = function() {
  return /** @type{?proto.commUnity.Customer} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Customer, 4));
};


/**
 * @param {?proto.commUnity.Customer|undefined} value
 * @return {!proto.commUnity.MemberToken} returns this
*/
proto.commUnity.MemberToken.prototype.setCustomer = function(value) {
  return jspb.Message.setWrapperField(this, 4, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.MemberToken} returns this
 */
proto.commUnity.MemberToken.prototype.clearCustomer = function() {
  return this.setCustomer(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.MemberToken.prototype.hasCustomer = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional MobileApp MobileApp = 5;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.MemberToken.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.MemberToken} returns this
 */
proto.commUnity.MemberToken.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional Group Group = 10;
 * @return {?proto.commUnity.Group}
 */
proto.commUnity.MemberToken.prototype.getGroup = function() {
  return /** @type{?proto.commUnity.Group} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Group, 10));
};


/**
 * @param {?proto.commUnity.Group|undefined} value
 * @return {!proto.commUnity.MemberToken} returns this
*/
proto.commUnity.MemberToken.prototype.setGroup = function(value) {
  return jspb.Message.setWrapperField(this, 10, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.MemberToken} returns this
 */
proto.commUnity.MemberToken.prototype.clearGroup = function() {
  return this.setGroup(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.MemberToken.prototype.hasGroup = function() {
  return jspb.Message.getField(this, 10) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MinVersion.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MinVersion.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MinVersion} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MinVersion.toObject = function(includeInstance, msg) {
  var f, obj = {
    android: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ios: jspb.Message.getFieldWithDefault(msg, 2, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MinVersion}
 */
proto.commUnity.MinVersion.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MinVersion;
  return proto.commUnity.MinVersion.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MinVersion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MinVersion}
 */
proto.commUnity.MinVersion.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setAndroid(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setIos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MinVersion.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MinVersion.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MinVersion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MinVersion.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getAndroid();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getIos();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
};


/**
 * optional string android = 1;
 * @return {string}
 */
proto.commUnity.MinVersion.prototype.getAndroid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MinVersion} returns this
 */
proto.commUnity.MinVersion.prototype.setAndroid = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string ios = 2;
 * @return {string}
 */
proto.commUnity.MinVersion.prototype.getIos = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MinVersion} returns this
 */
proto.commUnity.MinVersion.prototype.setIos = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.commUnity.SearchResult.oneofGroups_ = [[1,2,3,4,5,6]];

/**
 * @enum {number}
 */
proto.commUnity.SearchResult.ResultCase = {
  RESULT_NOT_SET: 0,
  NEWS: 1,
  BENEFIT: 2,
  MEMBER: 3,
  PLACE: 4,
  ALBUM: 5,
  PRODUCT: 6
};

/**
 * @return {proto.commUnity.SearchResult.ResultCase}
 */
proto.commUnity.SearchResult.prototype.getResultCase = function() {
  return /** @type {proto.commUnity.SearchResult.ResultCase} */(jspb.Message.computeOneofCase(this, proto.commUnity.SearchResult.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.SearchResult.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.SearchResult.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.SearchResult} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SearchResult.toObject = function(includeInstance, msg) {
  var f, obj = {
    news: (f = msg.getNews()) && commUnity_pb.News.toObject(includeInstance, f),
    benefit: (f = msg.getBenefit()) && commUnity_pb.Benefit.toObject(includeInstance, f),
    member: (f = msg.getMember()) && commUnity_pb.Member.toObject(includeInstance, f),
    place: (f = msg.getPlace()) && commUnity_pb.Place.toObject(includeInstance, f),
    album: (f = msg.getAlbum()) && commUnity_pb.Album.toObject(includeInstance, f),
    product: (f = msg.getProduct()) && shop_pb.Product.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.SearchResult}
 */
proto.commUnity.SearchResult.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.SearchResult;
  return proto.commUnity.SearchResult.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.SearchResult} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.SearchResult}
 */
proto.commUnity.SearchResult.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commUnity_pb.News;
      reader.readMessage(value,commUnity_pb.News.deserializeBinaryFromReader);
      msg.setNews(value);
      break;
    case 2:
      var value = new commUnity_pb.Benefit;
      reader.readMessage(value,commUnity_pb.Benefit.deserializeBinaryFromReader);
      msg.setBenefit(value);
      break;
    case 3:
      var value = new commUnity_pb.Member;
      reader.readMessage(value,commUnity_pb.Member.deserializeBinaryFromReader);
      msg.setMember(value);
      break;
    case 4:
      var value = new commUnity_pb.Place;
      reader.readMessage(value,commUnity_pb.Place.deserializeBinaryFromReader);
      msg.setPlace(value);
      break;
    case 5:
      var value = new commUnity_pb.Album;
      reader.readMessage(value,commUnity_pb.Album.deserializeBinaryFromReader);
      msg.setAlbum(value);
      break;
    case 6:
      var value = new shop_pb.Product;
      reader.readMessage(value,shop_pb.Product.deserializeBinaryFromReader);
      msg.setProduct(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.SearchResult.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.SearchResult.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.SearchResult} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SearchResult.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getNews();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commUnity_pb.News.serializeBinaryToWriter
    );
  }
  f = message.getBenefit();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      commUnity_pb.Benefit.serializeBinaryToWriter
    );
  }
  f = message.getMember();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      commUnity_pb.Member.serializeBinaryToWriter
    );
  }
  f = message.getPlace();
  if (f != null) {
    writer.writeMessage(
      4,
      f,
      commUnity_pb.Place.serializeBinaryToWriter
    );
  }
  f = message.getAlbum();
  if (f != null) {
    writer.writeMessage(
      5,
      f,
      commUnity_pb.Album.serializeBinaryToWriter
    );
  }
  f = message.getProduct();
  if (f != null) {
    writer.writeMessage(
      6,
      f,
      shop_pb.Product.serializeBinaryToWriter
    );
  }
};


/**
 * optional News news = 1;
 * @return {?proto.commUnity.News}
 */
proto.commUnity.SearchResult.prototype.getNews = function() {
  return /** @type{?proto.commUnity.News} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.News, 1));
};


/**
 * @param {?proto.commUnity.News|undefined} value
 * @return {!proto.commUnity.SearchResult} returns this
*/
proto.commUnity.SearchResult.prototype.setNews = function(value) {
  return jspb.Message.setOneofWrapperField(this, 1, proto.commUnity.SearchResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.SearchResult} returns this
 */
proto.commUnity.SearchResult.prototype.clearNews = function() {
  return this.setNews(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.SearchResult.prototype.hasNews = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Benefit Benefit = 2;
 * @return {?proto.commUnity.Benefit}
 */
proto.commUnity.SearchResult.prototype.getBenefit = function() {
  return /** @type{?proto.commUnity.Benefit} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Benefit, 2));
};


/**
 * @param {?proto.commUnity.Benefit|undefined} value
 * @return {!proto.commUnity.SearchResult} returns this
*/
proto.commUnity.SearchResult.prototype.setBenefit = function(value) {
  return jspb.Message.setOneofWrapperField(this, 2, proto.commUnity.SearchResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.SearchResult} returns this
 */
proto.commUnity.SearchResult.prototype.clearBenefit = function() {
  return this.setBenefit(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.SearchResult.prototype.hasBenefit = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Member member = 3;
 * @return {?proto.commUnity.Member}
 */
proto.commUnity.SearchResult.prototype.getMember = function() {
  return /** @type{?proto.commUnity.Member} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Member, 3));
};


/**
 * @param {?proto.commUnity.Member|undefined} value
 * @return {!proto.commUnity.SearchResult} returns this
*/
proto.commUnity.SearchResult.prototype.setMember = function(value) {
  return jspb.Message.setOneofWrapperField(this, 3, proto.commUnity.SearchResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.SearchResult} returns this
 */
proto.commUnity.SearchResult.prototype.clearMember = function() {
  return this.setMember(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.SearchResult.prototype.hasMember = function() {
  return jspb.Message.getField(this, 3) != null;
};


/**
 * optional Place Place = 4;
 * @return {?proto.commUnity.Place}
 */
proto.commUnity.SearchResult.prototype.getPlace = function() {
  return /** @type{?proto.commUnity.Place} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Place, 4));
};


/**
 * @param {?proto.commUnity.Place|undefined} value
 * @return {!proto.commUnity.SearchResult} returns this
*/
proto.commUnity.SearchResult.prototype.setPlace = function(value) {
  return jspb.Message.setOneofWrapperField(this, 4, proto.commUnity.SearchResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.SearchResult} returns this
 */
proto.commUnity.SearchResult.prototype.clearPlace = function() {
  return this.setPlace(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.SearchResult.prototype.hasPlace = function() {
  return jspb.Message.getField(this, 4) != null;
};


/**
 * optional Album album = 5;
 * @return {?proto.commUnity.Album}
 */
proto.commUnity.SearchResult.prototype.getAlbum = function() {
  return /** @type{?proto.commUnity.Album} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Album, 5));
};


/**
 * @param {?proto.commUnity.Album|undefined} value
 * @return {!proto.commUnity.SearchResult} returns this
*/
proto.commUnity.SearchResult.prototype.setAlbum = function(value) {
  return jspb.Message.setOneofWrapperField(this, 5, proto.commUnity.SearchResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.SearchResult} returns this
 */
proto.commUnity.SearchResult.prototype.clearAlbum = function() {
  return this.setAlbum(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.SearchResult.prototype.hasAlbum = function() {
  return jspb.Message.getField(this, 5) != null;
};


/**
 * optional Product Product = 6;
 * @return {?proto.commUnity.Product}
 */
proto.commUnity.SearchResult.prototype.getProduct = function() {
  return /** @type{?proto.commUnity.Product} */ (
    jspb.Message.getWrapperField(this, shop_pb.Product, 6));
};


/**
 * @param {?proto.commUnity.Product|undefined} value
 * @return {!proto.commUnity.SearchResult} returns this
*/
proto.commUnity.SearchResult.prototype.setProduct = function(value) {
  return jspb.Message.setOneofWrapperField(this, 6, proto.commUnity.SearchResult.oneofGroups_[0], value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.SearchResult} returns this
 */
proto.commUnity.SearchResult.prototype.clearProduct = function() {
  return this.setProduct(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.SearchResult.prototype.hasProduct = function() {
  return jspb.Message.getField(this, 6) != null;
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.SearchRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.SearchRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.SearchRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SearchRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    text: jspb.Message.getFieldWithDefault(msg, 1, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.SearchRequest}
 */
proto.commUnity.SearchRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.SearchRequest;
  return proto.commUnity.SearchRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.SearchRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.SearchRequest}
 */
proto.commUnity.SearchRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setText(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.SearchRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.SearchRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.SearchRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SearchRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getText();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
};


/**
 * optional string text = 1;
 * @return {string}
 */
proto.commUnity.SearchRequest.prototype.getText = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SearchRequest} returns this
 */
proto.commUnity.SearchRequest.prototype.setText = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};



/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.commUnity.MemberProfileRequest.repeatedFields_ = [26];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.MemberProfileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.MemberProfileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.MemberProfileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberProfileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    title: jspb.Message.getFieldWithDefault(msg, 3, 0),
    company: jspb.Message.getFieldWithDefault(msg, 6, ""),
    pb_function: jspb.Message.getFieldWithDefault(msg, 7, ""),
    photo: msg.getPhoto_asB64(),
    activitysector: jspb.Message.getFieldWithDefault(msg, 10, ""),
    description: jspb.Message.getFieldWithDefault(msg, 11, ""),
    url: jspb.Message.getFieldWithDefault(msg, 12, ""),
    professionaladdress: (f = msg.getProfessionaladdress()) && commUnity_pb.Address.toObject(includeInstance, f),
    personaladdress: (f = msg.getPersonaladdress()) && commUnity_pb.Address.toObject(includeInstance, f),
    shortdescription: jspb.Message.getFieldWithDefault(msg, 17, ""),
    birthday: jspb.Message.getFieldWithDefault(msg, 21, 0),
    socialnetwork: (f = msg.getSocialnetwork()) && commUnity_pb.SocialNetwork.toObject(includeInstance, f),
    interestList: (f = jspb.Message.getRepeatedField(msg, 26)) == null ? undefined : f,
    index: jspb.Message.getFloatingPointFieldWithDefault(msg, 30, 0.0),
    license: jspb.Message.getFieldWithDefault(msg, 31, ""),
    polosize: jspb.Message.getFieldWithDefault(msg, 42, 0),
    pb_private: jspb.Message.getBooleanFieldWithDefault(msg, 43, false)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.MemberProfileRequest}
 */
proto.commUnity.MemberProfileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.MemberProfileRequest;
  return proto.commUnity.MemberProfileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.MemberProfileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.MemberProfileRequest}
 */
proto.commUnity.MemberProfileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 3:
      var value = /** @type {!proto.commUnity.Title} */ (reader.readEnum());
      msg.setTitle(value);
      break;
    case 6:
      var value = /** @type {string} */ (reader.readString());
      msg.setCompany(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFunction(value);
      break;
    case 8:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.setPhoto(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setActivitysector(value);
      break;
    case 11:
      var value = /** @type {string} */ (reader.readString());
      msg.setDescription(value);
      break;
    case 12:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 15:
      var value = new commUnity_pb.Address;
      reader.readMessage(value,commUnity_pb.Address.deserializeBinaryFromReader);
      msg.setProfessionaladdress(value);
      break;
    case 16:
      var value = new commUnity_pb.Address;
      reader.readMessage(value,commUnity_pb.Address.deserializeBinaryFromReader);
      msg.setPersonaladdress(value);
      break;
    case 17:
      var value = /** @type {string} */ (reader.readString());
      msg.setShortdescription(value);
      break;
    case 21:
      var value = /** @type {number} */ (reader.readUint32());
      msg.setBirthday(value);
      break;
    case 25:
      var value = new commUnity_pb.SocialNetwork;
      reader.readMessage(value,commUnity_pb.SocialNetwork.deserializeBinaryFromReader);
      msg.setSocialnetwork(value);
      break;
    case 26:
      var value = /** @type {string} */ (reader.readString());
      msg.addInterest(value);
      break;
    case 30:
      var value = /** @type {number} */ (reader.readFloat());
      msg.setIndex(value);
      break;
    case 31:
      var value = /** @type {string} */ (reader.readString());
      msg.setLicense(value);
      break;
    case 42:
      var value = /** @type {!proto.commUnity.PoloSize} */ (reader.readEnum());
      msg.setPolosize(value);
      break;
    case 43:
      var value = /** @type {boolean} */ (reader.readBool());
      msg.setPrivate(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.MemberProfileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.MemberProfileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.MemberProfileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.MemberProfileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getTitle();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCompany();
  if (f.length > 0) {
    writer.writeString(
      6,
      f
    );
  }
  f = message.getFunction();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getPhoto_asU8();
  if (f.length > 0) {
    writer.writeBytes(
      8,
      f
    );
  }
  f = message.getActivitysector();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getDescription();
  if (f.length > 0) {
    writer.writeString(
      11,
      f
    );
  }
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      12,
      f
    );
  }
  f = message.getProfessionaladdress();
  if (f != null) {
    writer.writeMessage(
      15,
      f,
      commUnity_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getPersonaladdress();
  if (f != null) {
    writer.writeMessage(
      16,
      f,
      commUnity_pb.Address.serializeBinaryToWriter
    );
  }
  f = message.getShortdescription();
  if (f.length > 0) {
    writer.writeString(
      17,
      f
    );
  }
  f = message.getBirthday();
  if (f !== 0) {
    writer.writeUint32(
      21,
      f
    );
  }
  f = message.getSocialnetwork();
  if (f != null) {
    writer.writeMessage(
      25,
      f,
      commUnity_pb.SocialNetwork.serializeBinaryToWriter
    );
  }
  f = message.getInterestList();
  if (f.length > 0) {
    writer.writeRepeatedString(
      26,
      f
    );
  }
  f = message.getIndex();
  if (f !== 0.0) {
    writer.writeFloat(
      30,
      f
    );
  }
  f = message.getLicense();
  if (f.length > 0) {
    writer.writeString(
      31,
      f
    );
  }
  f = message.getPolosize();
  if (f !== 0.0) {
    writer.writeEnum(
      42,
      f
    );
  }
  f = message.getPrivate();
  if (f) {
    writer.writeBool(
      43,
      f
    );
  }
};


/**
 * optional Title title = 3;
 * @return {!proto.commUnity.Title}
 */
proto.commUnity.MemberProfileRequest.prototype.getTitle = function() {
  return /** @type {!proto.commUnity.Title} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.commUnity.Title} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setTitle = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string company = 6;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getCompany = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 6, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setCompany = function(value) {
  return jspb.Message.setProto3StringField(this, 6, value);
};


/**
 * optional string function = 7;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getFunction = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setFunction = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional bytes photo = 8;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getPhoto = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 8, ""));
};


/**
 * optional bytes photo = 8;
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getPhoto_asB64 = function() {
  return /** @type {string} */ (jspb.Message.bytesAsB64(
      this.getPhoto()));
};


/**
 * optional bytes photo = 8;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPhoto()`
 * @return {!Uint8Array}
 */
proto.commUnity.MemberProfileRequest.prototype.getPhoto_asU8 = function() {
  return /** @type {!Uint8Array} */ (jspb.Message.bytesAsU8(
      this.getPhoto()));
};


/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setPhoto = function(value) {
  return jspb.Message.setProto3BytesField(this, 8, value);
};


/**
 * optional string activitySector = 10;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getActivitysector = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setActivitysector = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional string description = 11;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getDescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 11, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setDescription = function(value) {
  return jspb.Message.setProto3StringField(this, 11, value);
};


/**
 * optional string URL = 12;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 12, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 12, value);
};


/**
 * optional Address professionalAddress = 15;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.MemberProfileRequest.prototype.getProfessionaladdress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Address, 15));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
*/
proto.commUnity.MemberProfileRequest.prototype.setProfessionaladdress = function(value) {
  return jspb.Message.setWrapperField(this, 15, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.clearProfessionaladdress = function() {
  return this.setProfessionaladdress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.MemberProfileRequest.prototype.hasProfessionaladdress = function() {
  return jspb.Message.getField(this, 15) != null;
};


/**
 * optional Address personalAddress = 16;
 * @return {?proto.commUnity.Address}
 */
proto.commUnity.MemberProfileRequest.prototype.getPersonaladdress = function() {
  return /** @type{?proto.commUnity.Address} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Address, 16));
};


/**
 * @param {?proto.commUnity.Address|undefined} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
*/
proto.commUnity.MemberProfileRequest.prototype.setPersonaladdress = function(value) {
  return jspb.Message.setWrapperField(this, 16, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.clearPersonaladdress = function() {
  return this.setPersonaladdress(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.MemberProfileRequest.prototype.hasPersonaladdress = function() {
  return jspb.Message.getField(this, 16) != null;
};


/**
 * optional string shortDescription = 17;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getShortdescription = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 17, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setShortdescription = function(value) {
  return jspb.Message.setProto3StringField(this, 17, value);
};


/**
 * optional uint32 Birthday = 21;
 * @return {number}
 */
proto.commUnity.MemberProfileRequest.prototype.getBirthday = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 21, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setBirthday = function(value) {
  return jspb.Message.setProto3IntField(this, 21, value);
};


/**
 * optional SocialNetwork socialNetwork = 25;
 * @return {?proto.commUnity.SocialNetwork}
 */
proto.commUnity.MemberProfileRequest.prototype.getSocialnetwork = function() {
  return /** @type{?proto.commUnity.SocialNetwork} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.SocialNetwork, 25));
};


/**
 * @param {?proto.commUnity.SocialNetwork|undefined} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
*/
proto.commUnity.MemberProfileRequest.prototype.setSocialnetwork = function(value) {
  return jspb.Message.setWrapperField(this, 25, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.clearSocialnetwork = function() {
  return this.setSocialnetwork(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.MemberProfileRequest.prototype.hasSocialnetwork = function() {
  return jspb.Message.getField(this, 25) != null;
};


/**
 * repeated string interest = 26;
 * @return {!Array<string>}
 */
proto.commUnity.MemberProfileRequest.prototype.getInterestList = function() {
  return /** @type {!Array<string>} */ (jspb.Message.getRepeatedField(this, 26));
};


/**
 * @param {!Array<string>} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setInterestList = function(value) {
  return jspb.Message.setField(this, 26, value || []);
};


/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.addInterest = function(value, opt_index) {
  return jspb.Message.addToRepeatedField(this, 26, value, opt_index);
};


/**
 * Clears the list making it empty but non-null.
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.clearInterestList = function() {
  return this.setInterestList([]);
};


/**
 * optional float index = 30;
 * @return {number}
 */
proto.commUnity.MemberProfileRequest.prototype.getIndex = function() {
  return /** @type {number} */ (jspb.Message.getFloatingPointFieldWithDefault(this, 30, 0.0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setIndex = function(value) {
  return jspb.Message.setProto3FloatField(this, 30, value);
};


/**
 * optional string license = 31;
 * @return {string}
 */
proto.commUnity.MemberProfileRequest.prototype.getLicense = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 31, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setLicense = function(value) {
  return jspb.Message.setProto3StringField(this, 31, value);
};


/**
 * optional PoloSize poloSize = 42;
 * @return {!proto.commUnity.PoloSize}
 */
proto.commUnity.MemberProfileRequest.prototype.getPolosize = function() {
  return /** @type {!proto.commUnity.PoloSize} */ (jspb.Message.getFieldWithDefault(this, 42, 0));
};


/**
 * @param {!proto.commUnity.PoloSize} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setPolosize = function(value) {
  return jspb.Message.setProto3EnumField(this, 42, value);
};


/**
 * optional bool Private = 43;
 * @return {boolean}
 */
proto.commUnity.MemberProfileRequest.prototype.getPrivate = function() {
  return /** @type {boolean} */ (jspb.Message.getBooleanFieldWithDefault(this, 43, false));
};


/**
 * @param {boolean} value
 * @return {!proto.commUnity.MemberProfileRequest} returns this
 */
proto.commUnity.MemberProfileRequest.prototype.setPrivate = function(value) {
  return jspb.Message.setProto3BooleanField(this, 43, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.EventMobile.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.EventMobile.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.EventMobile} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventMobile.toObject = function(includeInstance, msg) {
  var f, obj = {
    event: (f = msg.getEvent()) && commUnity_pb.Event.toObject(includeInstance, f),
    attending: jspb.Message.getFieldWithDefault(msg, 2, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.EventMobile}
 */
proto.commUnity.EventMobile.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.EventMobile;
  return proto.commUnity.EventMobile.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.EventMobile} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.EventMobile}
 */
proto.commUnity.EventMobile.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new commUnity_pb.Event;
      reader.readMessage(value,commUnity_pb.Event.deserializeBinaryFromReader);
      msg.setEvent(value);
      break;
    case 2:
      var value = /** @type {!proto.commUnity.Attending} */ (reader.readEnum());
      msg.setAttending(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.EventMobile.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.EventMobile.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.EventMobile} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.EventMobile.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getEvent();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      commUnity_pb.Event.serializeBinaryToWriter
    );
  }
  f = message.getAttending();
  if (f !== 0.0) {
    writer.writeEnum(
      2,
      f
    );
  }
};


/**
 * optional Event event = 1;
 * @return {?proto.commUnity.Event}
 */
proto.commUnity.EventMobile.prototype.getEvent = function() {
  return /** @type{?proto.commUnity.Event} */ (
    jspb.Message.getWrapperField(this, commUnity_pb.Event, 1));
};


/**
 * @param {?proto.commUnity.Event|undefined} value
 * @return {!proto.commUnity.EventMobile} returns this
*/
proto.commUnity.EventMobile.prototype.setEvent = function(value) {
  return jspb.Message.setWrapperField(this, 1, value);
};


/**
 * Clears the message field making it undefined.
 * @return {!proto.commUnity.EventMobile} returns this
 */
proto.commUnity.EventMobile.prototype.clearEvent = function() {
  return this.setEvent(undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.EventMobile.prototype.hasEvent = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional Attending attending = 2;
 * @return {!proto.commUnity.Attending}
 */
proto.commUnity.EventMobile.prototype.getAttending = function() {
  return /** @type {!proto.commUnity.Attending} */ (jspb.Message.getFieldWithDefault(this, 2, 0));
};


/**
 * @param {!proto.commUnity.Attending} value
 * @return {!proto.commUnity.EventMobile} returns this
 */
proto.commUnity.EventMobile.prototype.setAttending = function(value) {
  return jspb.Message.setProto3EnumField(this, 2, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.SignInMobileRequest.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.SignInMobileRequest.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.SignInMobileRequest} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SignInMobileRequest.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    password: jspb.Message.getFieldWithDefault(msg, 2, ""),
    customerid: jspb.Message.getFieldWithDefault(msg, 3, ""),
    mobileapp: jspb.Message.getFieldWithDefault(msg, 5, 0),
    firebasetokenid: jspb.Message.getFieldWithDefault(msg, 7, ""),
    groupid: jspb.Message.getFieldWithDefault(msg, 10, ""),
    mobileos: jspb.Message.getFieldWithDefault(msg, 20, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.SignInMobileRequest}
 */
proto.commUnity.SignInMobileRequest.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.SignInMobileRequest;
  return proto.commUnity.SignInMobileRequest.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.SignInMobileRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.SignInMobileRequest}
 */
proto.commUnity.SignInMobileRequest.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setPassword(value);
      break;
    case 3:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 5:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    case 7:
      var value = /** @type {string} */ (reader.readString());
      msg.setFirebasetokenid(value);
      break;
    case 10:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupid(value);
      break;
    case 20:
      var value = /** @type {!proto.commUnity.OS} */ (reader.readEnum());
      msg.setMobileos(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.SignInMobileRequest.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.SignInMobileRequest.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.SignInMobileRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.SignInMobileRequest.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUsername();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getPassword();
  if (f.length > 0) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      3,
      f
    );
  }
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      5,
      f
    );
  }
  f = message.getFirebasetokenid();
  if (f.length > 0) {
    writer.writeString(
      7,
      f
    );
  }
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
      10,
      f
    );
  }
  f = message.getMobileos();
  if (f !== 0.0) {
    writer.writeEnum(
      20,
      f
    );
  }
};


/**
 * optional string UserName = 1;
 * @return {string}
 */
proto.commUnity.SignInMobileRequest.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignInMobileRequest} returns this
 */
proto.commUnity.SignInMobileRequest.prototype.setUsername = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional string Password = 2;
 * @return {string}
 */
proto.commUnity.SignInMobileRequest.prototype.getPassword = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignInMobileRequest} returns this
 */
proto.commUnity.SignInMobileRequest.prototype.setPassword = function(value) {
  return jspb.Message.setProto3StringField(this, 2, value);
};


/**
 * optional string CustomerID = 3;
 * @return {string}
 */
proto.commUnity.SignInMobileRequest.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 3, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignInMobileRequest} returns this
 */
proto.commUnity.SignInMobileRequest.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 3, value);
};


/**
 * optional MobileApp MobileApp = 5;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.SignInMobileRequest.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 5, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.SignInMobileRequest} returns this
 */
proto.commUnity.SignInMobileRequest.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 5, value);
};


/**
 * optional string FirebaseTokenID = 7;
 * @return {string}
 */
proto.commUnity.SignInMobileRequest.prototype.getFirebasetokenid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 7, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignInMobileRequest} returns this
 */
proto.commUnity.SignInMobileRequest.prototype.setFirebasetokenid = function(value) {
  return jspb.Message.setProto3StringField(this, 7, value);
};


/**
 * optional string GroupID = 10;
 * @return {string}
 */
proto.commUnity.SignInMobileRequest.prototype.getGroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 10, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.SignInMobileRequest} returns this
 */
proto.commUnity.SignInMobileRequest.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 10, value);
};


/**
 * optional OS MobileOS = 20;
 * @return {!proto.commUnity.OS}
 */
proto.commUnity.SignInMobileRequest.prototype.getMobileos = function() {
  return /** @type {!proto.commUnity.OS} */ (jspb.Message.getFieldWithDefault(this, 20, 0));
};


/**
 * @param {!proto.commUnity.OS} value
 * @return {!proto.commUnity.SignInMobileRequest} returns this
 */
proto.commUnity.SignInMobileRequest.prototype.setMobileos = function(value) {
  return jspb.Message.setProto3EnumField(this, 20, value);
};



/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.commUnity.RequestPassword.oneofGroups_ = [[1,2]];

/**
 * @enum {number}
 */
proto.commUnity.RequestPassword.UseremailchoiceCase = {
  USEREMAILCHOICE_NOT_SET: 0,
  USERNAME: 1,
  EMAIL: 2
};

/**
 * @return {proto.commUnity.RequestPassword.UseremailchoiceCase}
 */
proto.commUnity.RequestPassword.prototype.getUseremailchoiceCase = function() {
  return /** @type {proto.commUnity.RequestPassword.UseremailchoiceCase} */(jspb.Message.computeOneofCase(this, proto.commUnity.RequestPassword.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.RequestPassword.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.RequestPassword.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.RequestPassword} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestPassword.toObject = function(includeInstance, msg) {
  var f, obj = {
    username: jspb.Message.getFieldWithDefault(msg, 1, ""),
    email: jspb.Message.getFieldWithDefault(msg, 2, ""),
    mobileapp: jspb.Message.getFieldWithDefault(msg, 3, 0),
    customerid: jspb.Message.getFieldWithDefault(msg, 4, ""),
    groupid: jspb.Message.getFieldWithDefault(msg, 5, "")
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.RequestPassword}
 */
proto.commUnity.RequestPassword.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.RequestPassword;
  return proto.commUnity.RequestPassword.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.RequestPassword} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.RequestPassword}
 */
proto.commUnity.RequestPassword.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUsername(value);
      break;
    case 2:
      var value = /** @type {string} */ (reader.readString());
      msg.setEmail(value);
      break;
    case 3:
      var value = /** @type {!proto.commUnity.MobileApp} */ (reader.readEnum());
      msg.setMobileapp(value);
      break;
    case 4:
      var value = /** @type {string} */ (reader.readString());
      msg.setCustomerid(value);
      break;
    case 5:
      var value = /** @type {string} */ (reader.readString());
      msg.setGroupid(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.RequestPassword.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.RequestPassword.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.RequestPassword} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.RequestPassword.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = /** @type {string} */ (jspb.Message.getField(message, 1));
  if (f != null) {
    writer.writeString(
      1,
      f
    );
  }
  f = /** @type {string} */ (jspb.Message.getField(message, 2));
  if (f != null) {
    writer.writeString(
      2,
      f
    );
  }
  f = message.getMobileapp();
  if (f !== 0.0) {
    writer.writeEnum(
      3,
      f
    );
  }
  f = message.getCustomerid();
  if (f.length > 0) {
    writer.writeString(
      4,
      f
    );
  }
  f = message.getGroupid();
  if (f.length > 0) {
    writer.writeString(
      5,
      f
    );
  }
};


/**
 * optional string UserName = 1;
 * @return {string}
 */
proto.commUnity.RequestPassword.prototype.getUsername = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestPassword} returns this
 */
proto.commUnity.RequestPassword.prototype.setUsername = function(value) {
  return jspb.Message.setOneofField(this, 1, proto.commUnity.RequestPassword.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.commUnity.RequestPassword} returns this
 */
proto.commUnity.RequestPassword.prototype.clearUsername = function() {
  return jspb.Message.setOneofField(this, 1, proto.commUnity.RequestPassword.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.RequestPassword.prototype.hasUsername = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional string Email = 2;
 * @return {string}
 */
proto.commUnity.RequestPassword.prototype.getEmail = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 2, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestPassword} returns this
 */
proto.commUnity.RequestPassword.prototype.setEmail = function(value) {
  return jspb.Message.setOneofField(this, 2, proto.commUnity.RequestPassword.oneofGroups_[0], value);
};


/**
 * Clears the field making it undefined.
 * @return {!proto.commUnity.RequestPassword} returns this
 */
proto.commUnity.RequestPassword.prototype.clearEmail = function() {
  return jspb.Message.setOneofField(this, 2, proto.commUnity.RequestPassword.oneofGroups_[0], undefined);
};


/**
 * Returns whether this field is set.
 * @return {boolean}
 */
proto.commUnity.RequestPassword.prototype.hasEmail = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional MobileApp MobileApp = 3;
 * @return {!proto.commUnity.MobileApp}
 */
proto.commUnity.RequestPassword.prototype.getMobileapp = function() {
  return /** @type {!proto.commUnity.MobileApp} */ (jspb.Message.getFieldWithDefault(this, 3, 0));
};


/**
 * @param {!proto.commUnity.MobileApp} value
 * @return {!proto.commUnity.RequestPassword} returns this
 */
proto.commUnity.RequestPassword.prototype.setMobileapp = function(value) {
  return jspb.Message.setProto3EnumField(this, 3, value);
};


/**
 * optional string CustomerID = 4;
 * @return {string}
 */
proto.commUnity.RequestPassword.prototype.getCustomerid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 4, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestPassword} returns this
 */
proto.commUnity.RequestPassword.prototype.setCustomerid = function(value) {
  return jspb.Message.setProto3StringField(this, 4, value);
};


/**
 * optional string GroupID = 5;
 * @return {string}
 */
proto.commUnity.RequestPassword.prototype.getGroupid = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 5, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.RequestPassword} returns this
 */
proto.commUnity.RequestPassword.prototype.setGroupid = function(value) {
  return jspb.Message.setProto3StringField(this, 5, value);
};





if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * Optional fields that are not set will be set to undefined.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
 * @param {boolean=} opt_includeInstance Deprecated. whether to include the
 *     JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @return {!Object}
 */
proto.commUnity.CPSURL.prototype.toObject = function(opt_includeInstance) {
  return proto.commUnity.CPSURL.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Deprecated. Whether to include
 *     the JSPB instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.commUnity.CPSURL} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CPSURL.toObject = function(includeInstance, msg) {
  var f, obj = {
    url: jspb.Message.getFieldWithDefault(msg, 1, ""),
    ttl: jspb.Message.getFieldWithDefault(msg, 10, 0)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.commUnity.CPSURL}
 */
proto.commUnity.CPSURL.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.commUnity.CPSURL;
  return proto.commUnity.CPSURL.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.commUnity.CPSURL} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.commUnity.CPSURL}
 */
proto.commUnity.CPSURL.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {string} */ (reader.readString());
      msg.setUrl(value);
      break;
    case 10:
      var value = /** @type {number} */ (reader.readInt32());
      msg.setTtl(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.commUnity.CPSURL.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.commUnity.CPSURL.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.commUnity.CPSURL} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.commUnity.CPSURL.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getUrl();
  if (f.length > 0) {
    writer.writeString(
      1,
      f
    );
  }
  f = message.getTtl();
  if (f !== 0) {
    writer.writeInt32(
      10,
      f
    );
  }
};


/**
 * optional string url = 1;
 * @return {string}
 */
proto.commUnity.CPSURL.prototype.getUrl = function() {
  return /** @type {string} */ (jspb.Message.getFieldWithDefault(this, 1, ""));
};


/**
 * @param {string} value
 * @return {!proto.commUnity.CPSURL} returns this
 */
proto.commUnity.CPSURL.prototype.setUrl = function(value) {
  return jspb.Message.setProto3StringField(this, 1, value);
};


/**
 * optional int32 TTL = 10;
 * @return {number}
 */
proto.commUnity.CPSURL.prototype.getTtl = function() {
  return /** @type {number} */ (jspb.Message.getFieldWithDefault(this, 10, 0));
};


/**
 * @param {number} value
 * @return {!proto.commUnity.CPSURL} returns this
 */
proto.commUnity.CPSURL.prototype.setTtl = function(value) {
  return jspb.Message.setProto3IntField(this, 10, value);
};


goog.object.extend(exports, proto.commUnity);
