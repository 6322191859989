<app-toolbar page="album"></app-toolbar>

<div class="album">
  <h2 class="album-title">{{album?.getTitle()}}</h2>
  <h3 class="album-description" [innerHTML]="album?.getDescription()"></h3>

  <div class="list">
    <div class="list-photo" *ngFor="let photo of album?.getImagesList(); let ipho = index">
      <div class="photo" (click)="previewme(photo, ipho)" [ngStyle]="{'background-image': 'url(' + photo + ')'}"></div>
    </div>
  </div>
</div>

<div class="img-preview" [ngClass]="{'to-show': showpreview}">
<div class="bg" (click)="showpreview = false"></div>

<div class="splide image-slider-container">
	<div class="splide__track">
		<ul class="splide__list">
			<li class="splide__slide" *ngFor="let photo of album?.getImagesList()">
        <div class="splide__slide__container">
				<img [src]="photo" (click)="showpreview = false">
        </div>
			</li>
		</ul>
	</div>
</div>

</div>
