import { Component, OnInit, OnDestroy } from '@angular/core';
import { PhotoAlbumsComponent } from '../photo-albums.component';
import { Album } from 'src/app/libs/proto/commUnity_pb';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { GrpcAlbumLibService } from 'src/app/service/grpc/album/grpc-album-lib-service';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { ActivatedRoute } from '@angular/router';

declare let Splide: any;
@Component({
  selector: 'app-photo-album',
  templateUrl: './photo-album.component.html',
  styleUrls: ['./photo-album.component.sass']
})
export class PhotoAlbumComponent implements OnInit, OnDestroy {
  album: Album;
  actRouter$: Subscription;
  imageObject: Array<object> = [];
  imageSize: object = {};
  showpreview = false;
  photopreview = '';
  private newsplide: any;

  constructor(
    private grpcAlbumLib: GrpcAlbumLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute
  ) {
    userLib.setPageLanguage( this.translate );

    this.imageSize = {
      width: '100%',
    };
   }

   ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.grpcAlbumLib.getAlbum({
        Offline: true,
        call: {
          req: p.get('id'),
        }
      }).then( ns => {
        thise.album = ns[0];
      });
    });
  }


  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }
  get dateFormat(): string {
    return this.userLib.dateFormat;
  }
  previewme(p: string, ind: number) {
    if (this.newsplide === undefined) {
      this.newsplide = new Splide('.image-slider-container', {
        width: '100vw',
        height: '100vh',
        pagination : false,
        start: 0,
      }).mount();
    }
    this.newsplide.go('>' + ind);

    this.showpreview = true;
    this.photopreview = p;
  }
}
