import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { EventMobile } from '../../libs/proto/mobile_pb';
import { TranslateService } from '@ngx-translate/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GrpcEventLibService } from '../../service/grpc/event/grpc-event-lib.service';
import { Route } from '@angular/compiler/src/core';
import { AddressConversionService, AddressFromNominate } from '../../service/conversion/address/address-conversion.service';

import { DialogServiceService } from '../../service/dialog/dialog-service.service';
import { desktopMode } from '../../config/type';
import { DeviceLibService } from 'src/app/service/device/device-lib.service';
import * as moment from 'moment';
import { SwiperComponent } from 'swiper/angular';
import { SwiperOptions } from 'swiper';
import { nn } from 'date-fns/locale';

// install Swiper modules
// SwiperCore.use([Navigation]);

@Component({
  selector: 'app-event',
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.sass']
})
export class EventComponent implements OnInit, OnDestroy, AfterViewInit {
  // Reference to swiper object
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  // Swiper settings
  configSwiper: SwiperOptions = {
    enabled: true,
    initialSlide: 0,
    direction: 'horizontal',
  };

  // done is true when all events have been loaded.
  // this is to ensure that swiper does not load the
  // config before everything has loaded.
  done: boolean = false;

  events: EventMobile[];
  eventI = 0;

  landscapeEvt = window.matchMedia('(orientation: landscape)');
  isLandscape = false;

  menuType = 0;

  // Speed at which the swiper's slide is transitionning in ms.
  slideTransitionDuration: number = 300;

  private actRouter$: Subscription;

  constructor(
    private grpcLib: GrpcEventLibService,
    private translate: TranslateService,
    private userLib: UserLibService,
    private actRoute: ActivatedRoute,
    private route: Router,
    private addConvLib: AddressConversionService,
    private dlgLib: DialogServiceService,
    private detector: DeviceLibService,
  ) {
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    const thise = this;
    this.actRouter$ = this.actRoute.paramMap.subscribe( p => {
      thise.grpcLib.getEvent({
        Offline: true,
      }).then( ns => {
        const dd = ns.filter( n => n.getEvent().getId() === p.get('d'));
        // not found any
        if (dd.length === 0) {
          thise.route.navigateByUrl('/events');
          return;
        }

        thise.events = ns.filter( n => n.getEvent().getDate() === dd[0].getEvent().getDate());

        // Find the index of the array of events which corresponds to the id from the url.
        let slideIndex = thise.events.findIndex((event) => event.getEvent().getId() === dd[0].getEvent().getId());
        thise.configSwiper.initialSlide = slideIndex;
        thise.done = true; // load swiper
      });
    });

    this.isLandscape = this.detector.orientation === 'landscape';
    this.menuType = this.getmenuType();
    this.landscapeEvt.addEventListener('change', ev => {
       this.isLandscape = this.landscapeEvt.matches;
       this.menuType = this.getmenuType();
    });
  }
  ngAfterViewInit(): void {

  }
  ngOnDestroy(): void {
    this.actRouter$.unsubscribe();
  }

  // Transition to previous slide
  slidePrev() {
    this.swiper.swiperRef.slidePrev(this.slideTransitionDuration);
  }
  // Transition to next slide
  slideNext() {
    this.swiper.swiperRef.slideNext(this.slideTransitionDuration);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }

  get addressText() {
    return this.addConvLib.toAddressText(this.events[this.eventI]?.getEvent().getAddress());
  }

  showTime(e: EventMobile) {
    return !(((e?.getEvent().getTimefrom() || '00:00') === '00:00' ) &&
        ((e?.getEvent().getTimeto() || '23:59') === '23:59' ));
  }
  /**
   * check if guest?
   *
   * @returns true when guest
   */
  checkGuest() {
    if (this.userLib.Data.token?.getProfile().getIsguest()) {
      this.userLib.clear();
      this.userLib.Data.signOut = true;
      this.route.navigateByUrl('/login');
      return true;
    }

    return false;
  }
  attend(e: EventMobile) {
    if (e.getAttending() === 1) { return; }
    if (this.checkGuest()) { return; }

    this.grpcLib.eventAttend(e.getEvent().getId()).then( ok => {
      if (ok) {
        this.attendOK('-att');
      } else {
        this.attendFail();
      }

    }).catch( () => {
      this.attendFail();
    });
  }
  notAttend(e: EventMobile) {
    if (e.getAttending() === 2) { return; }
    if (this.checkGuest()) { return; }

    this.grpcLib.eventNotAttend(e.getEvent().getId()).then( ok => {
      if (ok) {
        this.attendOK('-unatt');
      } else {
        this.attendFail();
      }

    }).catch( () => {
      this.attendFail();
    });
  }
  private attendOK(type: string) {
    const thise = this;
    this.translate.get([
      'event.ok' + type,
      'event.title'
    ]).toPromise().then( t => {
      thise.dlgLib.show(t['event.ok' + type], t['event.title'], _ => {
        thise.grpcLib.getEvent({
          Offline: false
        }).finally( () => {
          thise.route.navigateByUrl('/events');
        });
      });
    });
  }
  private attendFail() {
    const thise = this;
    this.translate.get([
      'event.ko',
      'event.title'
    ]).toPromise().then( t => {
      thise.dlgLib.show(t['event.ko'], t['event.title']);
    });
  }
  isFuture(d: number) {
    return +moment().format('YYYYMMDD') < d;
  }

  allowAttends(em?: EventMobile) {
    if (!em) { return false; }
    // -1 unlimit
    if (em.getEvent().getQt() === -1) { return true; }

    // 0 not available
    // if attend, allow to see (so user can unattend)
    if (em.getEvent().getQt() === 0) { return em.getAttending() === 2; }

    return em.getEvent().getQt() > 0;
  }

  /**
   * menu type
   * - 0 = list
   * - 1 = box with image (2 cols)
   * - 2 = box with image (4 cols)
   */
   getmenuType() {
    // if not mobile, return default mobile
    if (!this.detector.isMobile()) {
      // if desktop mode = 3, force to use photo menu
      if (+desktopMode === 3) { return 1; }
      // if desktop mode = 4, force to use photo menu4
      if (+desktopMode === 4)  {
        if (this.isLandscape) { return 2;  }
        return 1;
      }

      return 0;
    }

    return this.userLib.Data.token?.getCustomer().getMobilemenutype();
  }
}
