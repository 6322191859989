import { Component, OnInit } from '@angular/core';
import { Leaderboard } from 'src/app/libs/proto/commUnity_pb';
import { Router } from '@angular/router';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { GrpcLeaderboardLibService } from 'src/app/service/grpc/leaderboard/grpc-leaderboard-lib.service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.sass']
})
export class LeaderboardComponent implements OnInit {
  leaderboard$: Promise<Leaderboard[]>;
  columnsToDisplay = ['name', 'brut', 'net'];


  constructor(
    private route: Router,
    private grpcLeaderboardLib: GrpcLeaderboardLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService
  ) {
    userLib.setPageLanguage( this.translate );
  }

  ngOnInit(): void {
    this.leaderboard$ = this.grpcLeaderboardLib.getLeaderboard({
      Offline: this.storeLib.cache.leaderboard || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }
  get backgroundColor() {
    return this.userLib.designToolbarBackgroundColor;
  }

  get foregroundColor() {
    return this.userLib.designToolbarTextColor;
  }

}
