import { Component, OnInit } from '@angular/core';
import { Album } from 'src/app/libs/proto/commUnity_pb';
import { Router } from '@angular/router';
import { UserLibService } from 'src/app/service/user/user-lib.service';
import { TranslateService } from '@ngx-translate/core';
import { StorageLibService } from 'src/app/service/storage/storage-lib.service';
import { GrpcAlbumLibService } from 'src/app/service/grpc/album/grpc-album-lib-service';
import { GrpcLibService } from 'src/app/service/grpc/grpc-lib.service';
import { of } from 'rxjs';

@Component({
  selector: 'app-photo-albums',
  templateUrl: './photo-albums.component.html',
  styleUrls: ['./photo-albums.component.sass']
})
export class PhotoAlbumsComponent implements OnInit {
  albums$: Promise<Album[]>;

  constructor(
    private route: Router,
    private grpcAlbumLib: GrpcAlbumLibService,
    private userLib: UserLibService,
    private translate: TranslateService,
    private storeLib: StorageLibService
  ) { }

  ngOnInit(): void {
    if (this.storeLib.get('album')){
      this.storeLib.set('album', null);
      this.storeLib.cache.album = false;
    }
    this.albums$ = this.grpcAlbumLib.getAlbum({
      Offline: this.storeLib.cache.album || false,
    }).catch( (e: Error) => {
      if (e.message === GrpcLibService.ERR_SIGIN) {

        this.userLib.clear();
        this.route.navigateByUrl('/login');
        return;
      }
      // return to complete
      return of([]).toPromise();
    });
  }
  open(id: string){
    this.route.navigate(['/album', id]);
  }

  get dateFormat(): string {
    return this.userLib.dateFormat;
  }
  stop(e: MouseEvent) {
    e.stopPropagation();
  }

}
