import { Component, OnInit } from '@angular/core';
import { UserLibService } from '../../service/user/user-lib.service';
import { Router } from '@angular/router';
import { GrpcDisclaimerLibService } from '../../service/grpc/disclaimer/grpc-disclaimer-lib.service';
import { Disclaimer } from '../../libs/proto/commUnity_pb';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.sass']
})
export class DisclaimerComponent implements OnInit {

  disclaimLoading = true;
  disclaim?: Disclaimer;
  disclaimAccept = false;

  constructor(
    private route: Router,
    private userLib: UserLibService,
    private grpcLib: GrpcDisclaimerLibService,
    private toast: MatSnackBar,
    private translate: TranslateService
  ) {
    if ((this.userLib.disclaimerToken || null) == null) {
      this.route.navigateByUrl('/home');
    }
  }

  ngOnInit(): void {
    // reset token
    this.userLib.clear();
    this.grpcLib.getDisclaimer( this.userLib.disclaimerToken?.getToken() )
      .then(d => {
        this.disclaimLoading = false;
        this.disclaim = d;
    }).finally( () => {
      this.disclaimLoading = false;
    });
  }

  accept() {
    const thise = this;
    this.grpcLib.approveDisclaimer( this.userLib.disclaimerToken?.getToken())
    .catch( e => {
      thise.translate.get([
        'disclaimer.title',
        'disclaimer.error'
      ]).toPromise().then( t => {

        thise.toast.open(
          t['disclaimer.error'], 'x', {
            duration: 500
          }
        );

      });
    })
    .finally( () => {
      if (this.userLib.disclaimerCallbackURL) {
        this.userLib.updateUser(this.userLib.disclaimerToken);
        this.userLib.disclaimerToken = null;
        this.route.navigateByUrl('/home');

        return;
      }
      this.userLib.afterLogin(this.userLib.disclaimerToken);
    });

  }
}
