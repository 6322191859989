<app-toolbar page="streams"></app-toolbar>
<div class="form-list with-bg" [ngStyle]="{'background-image': 'url(' + (backgroundImage | pipeWebp) +')','background-size': backgroundSize}">
<div class="company-logo">
  <div class="logo" [ngStyle]="{'background-image': 'url(' + (logo | pipeWebp) +')'}"></div>
  <h1 [ngStyle]="{'color': textColor }">{{ customerName }}</h1>
</div>

  <div class="menus" *ngIf="menuType===0">
    <ul>
      <li *ngFor="let stream of Everstreams$ | async as streams">
        <div class="bg" [ngStyle]="{'background-color': backgroundColor}"></div>
        <img onerror="this.onerror=null;this.src='assets/images/1x1.png';" *ngIf="stream?.getIcon()" src="{{ stream?.getIcon() }}" class="icon-menu" />
        <div class="a-contain">
          <a *ngIf="!stream.getOpeninside()" [ngStyle]="{'color': textColor}" href="{{ stream.getUrl() }}" target="_blank">
            <span>{{ stream.getName() }}</span>
          </a>
          <a *ngIf="stream.getOpeninside()" [ngStyle]="{'color': textColor}" (click)="open(stream.getId())">
            <span>{{ stream.getName() }}</span>
          </a>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="menuType===1" class="menus-photo">
    <div class="menu-col" *ngFor="let stream of Everstreams$ | async as streams">
      <a *ngIf="!canOpenInside(stream)" target="_blank" [href]="stream.getUrl()" routerstreamActive="router-stream-active">
        <div *ngIf="!stream.getOpeninside()" [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + stream.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ stream.getName() }}
        </span>
      </a>
      <a *ngIf="canOpenInside(stream)" (click)="open(stream.getId())">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + stream.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ stream.getName() }}
        </span>
      </a>
    </div>
  </div>

  <div *ngIf="menuType===2" class="menus-tablet">
    <div class="menu-col" *ngFor="let stream of Everstreams$ | async as streams">
      <a *ngIf="!canOpenInside(stream)" target="_blank" [href]="stream.getUrl()" routerstreamActive="router-stream-active">
        <div class="menu-col-in">
        <div *ngIf="!stream.getOpeninside()" [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + stream.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ stream.getName() }}
        </span>
        </div>
      </a>
      <a *ngIf="canOpenInside(stream)" (click)="open(stream.getId())">
        <div class="menu-col-in">
        <div [ngStyle]="{'background-color': backgroundColor}"></div>
        <div class="menu-col-img" [ngStyle]="{'background-image': 'url(' + stream.getPhoto() }"></div>
          <span [ngStyle]="{'color': textColor}">
          {{ stream.getName() }}
        </span>
        </div>
      </a>
    </div>
  </div>
</div>

<ng-template #loading>
  <div class="form-wait-bg"></div>
  <div class="form-wait">
    <mat-spinner></mat-spinner>
  </div>
</ng-template>
