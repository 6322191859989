<app-toolbar page="event" [ml]="menuType==2?'15%':''" [mr]="menuType==2?'15%':''"></app-toolbar>

<div class="form-list" *ngIf="menuType!=2">

  <!-- `done` is true when all events have been loaded. -->
  <swiper #swiper *ngIf="done" [config]="configSwiper">

    <ng-template *ngFor="let event of events" swiperSlide>

  <div class="card-main">

    <mat-card class="event-card" >
      <div class="top-image"
        [ngStyle]="{'background-image': 'url(' + event?.getEvent().getImage() +')'}">
        <div class="swiper-button-prev" (click)="slidePrev()" [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==0}" [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>
        <div class="swiper-button-next" (click)="slideNext()" [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==events?.length-1}" [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>
      </div>
      <div
        *ngIf="isFuture(event?.getEvent().getDate())"
        class="box-subscribe">
        <button mat-button
          [disabled]="event?.getEvent().getQt() == 0"
          [color]=" event?.getAttending() === 1 ? '' : 'accent'"
          (click)="attend(event)">
          {{ 'event.accept' | translate }}
        </button>
        <button mat-button
          class="btn-right"
          [color]=" event?.getAttending() === 2 ? '' : 'accent'"
          (click)="notAttend(event)">
          {{ 'event.not_accept' | translate }}
        </button>
      </div>
      <div class="table">
        <div class="row">
          <div class="col1">
            <div class="col1">
              <div class="line">
                {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'DD/MM' }}
              </div>
              <div class="line">
                {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'YYYY' }}
              </div>
              <div class="line time" *ngIf="showTime(event)">
                {{ event?.getEvent().getTimefrom() }}
                -
                {{ event?.getEvent().getTimeto() }}
              </div>
            </div>
          </div>
          <div class="col">
            <mat-card-header>
              <mat-card-title>{{ event?.getEvent().getTitle() }}</mat-card-title>
              <mat-card-subtitle class="col-address">{{ addressText }}</mat-card-subtitle>
              <mat-card-subtitle
               *ngIf="event?.getEvent().getQt() && event?.getEvent().getQt() !== -1"
               class="col-ava">{{ ('event.available' | translate).replace('%s', event?.getEvent().getQt() ) }}</mat-card-subtitle>
              <mat-card-subtitle
                *ngIf="event?.getEvent().getUrl()"
                class="mb-0 info-link">
                <a class="pl-0" mat-flat-button target="_blank"

                  href="{{ event?.getEvent().getUrl() }}">
                  <mat-icon>language</mat-icon>
                  {{ 'new.more_info' | translate }}
                </a>
              </mat-card-subtitle>
            </mat-card-header>
          </div>
        </div>
      </div>
      <div class="table mt-0">
        <div class="row">
          <div class="col">
          <mat-card-subtitle
          class="mb-0 place-link"
          *ngIf="event?.getEvent().getPlaceid()">
          <a class="pl-0"
            mat-flat-button
            [routerLink]="[ '/place/event/', event?.getEvent().getId() , event?.getEvent().getPlaceid() ]" routerLinkActive="active">
            <mat-icon>place</mat-icon>
            {{ ('new.info_place' | translate).replace('%s', event?.getEvent().getPlacename()) }}
          </a>
        </mat-card-subtitle>
      </div>
        </div>
      </div>
    </mat-card>

    <div class="body">
      <p [innerHTML]="event?.getEvent().getDescription() | pipeDescription"></p>
    </div>
  </div>

  </ng-template>
  </swiper>
</div>

<div class="mode-tablet" *ngIf="menuType==2">

  <!-- `done` is true when all events have been loaded. -->
  <swiper #swiper *ngIf="done" [config]="configSwiper">

    <ng-template *ngFor="let event of events" swiperSlide>

      <div class="swiper-button-prev" (click)="slidePrev()" [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==0}" [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>
      <div class="swiper-button-next" (click)="slideNext()" [ngClass]="{'swiper-button-disabled':swiper.swiperRef?.realIndex==events?.length-1}" [ngStyle]="{'display':events?.length==1 ? 'none' : 'block'}"></div>

      <div class="card-main">
        <div class="left">
          <mat-card class="event-card">
            <div class="top-image" [ngStyle]="{'background-image': 'url(' + event?.getEvent().getImage() +')'}">
            </div>
            <div *ngIf="isFuture(event?.getEvent().getDate())" class="box-subscribe">
              <button mat-button [disabled]="event?.getEvent().getQt() == 0"
                [color]=" event?.getAttending() === 1 ? '' : 'accent'" (click)="attend(event)">
                {{ 'event.accept' | translate }}
              </button>
              <button mat-button class="btn-right" [color]=" event?.getAttending() === 2 ? '' : 'accent'"
                (click)="notAttend(event)">
                {{ 'event.not_accept' | translate }}
              </button>
            </div>
            <div class="table">
              <div class="row">
                <div class="col1">
                  <div class="col1">
                    <div class="line">
                      {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'DD/MM' }}
                    </div>
                    <div class="line">
                      {{ event?.getEvent().getDate() | amParse: 'YYYYMMDD' | amDateFormat: 'YYYY' }}
                    </div>
                    <div class="line time" *ngIf="showTime(event)">
                      {{ event?.getEvent().getTimefrom() }}
                      -
                      {{ event?.getEvent().getTimeto() }}
                    </div>
                  </div>
                </div>
                <div class="col">
                  <mat-card-header>
                    <mat-card-title>{{ event?.getEvent().getTitle() }}</mat-card-title>
                    <mat-card-subtitle class="col-address">{{ addressText }}</mat-card-subtitle>
                    <mat-card-subtitle *ngIf="event?.getEvent().getQt() && event?.getEvent().getQt() !== -1"
                      class="col-ava">{{ ('event.available' | translate).replace('%s', event?.getEvent().getQt() ) }}
                    </mat-card-subtitle>
                    <mat-card-subtitle *ngIf="event?.getEvent().getUrl()" class="mb-0 info-link">
                      <a class="pl-0" mat-flat-button target="_blank" href="{{ event?.getEvent().getUrl() }}">
                        <mat-icon>language</mat-icon>
                        {{ 'new.more_info' | translate }}
                      </a>
                    </mat-card-subtitle>
                  </mat-card-header>
                </div>
              </div>
            </div>
            <div class="table mt-0">
              <div class="row">
                <div class="col">
                  <mat-card-subtitle class="mb-0 place-link" *ngIf="event?.getEvent().getPlaceid()">
                    <a class="pl-0" mat-flat-button
                      [routerLink]="[ '/place/event/', event?.getEvent().getId() , event?.getEvent().getPlaceid() ]"
                      routerLinkActive="active">
                      <mat-icon>place</mat-icon>
                      {{ ('new.info_place' | translate).replace('%s', event?.getEvent().getPlacename()) }}
                    </a>
                  </mat-card-subtitle>
                </div>
              </div>
            </div>
          </mat-card>
        </div>

        <div class="right">
          <div class="body">
            <p [innerHTML]="event?.getEvent().getDescription() | pipeDescription"></p>
          </div>
        </div>
      </div>

    </ng-template>
  </swiper>
</div>
