import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { Authguard } from './class/authguard/authguard';
import { AdsComponent } from './component/ads/ads.component';
import { DisclaimerComponent } from './component/disclaimer/disclaimer.component';
import { EventsComponent } from './component/events/events.component';
import { HomeComponent } from './component/home/home.component';
import { LoginComponent } from './component/login/login.component';
import { NewComponent } from './component/news/new.component';
import { NewsComponent } from './component/news/news.component';
import { DialogComponent } from './service/dialog/dialog/dialog.component';
import { ToolbarComponent } from './component/toolbar/toolbar.component';

import { MatBottomSheetModule, MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatListModule } from '@angular/material/list';
import { MatToolbarModule } from '@angular/material/toolbar';

import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatBadgeModule } from '@angular/material/badge';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

import { AccountComponent } from './component/account/account.component';
import { SignupComponent } from './component/signup/signup.component';
import { PromptComponent } from './component/prompt/prompt.component';
import { PwaService } from './service/pwa/pwa.service';
import { AngularFireModule } from '@angular/fire/compat';
import { FirebaseComponent } from './service/firebase/firebase/firebase.component';
import { FirebaseService } from './service/firebase/firebase.service';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { NotificationComponent } from './service/firebase/notification/notification.component';
import { SearchComponent } from './component/search/search.component';
import { GroupBySearchType } from './component/search/group-by-search-type';
import { ProductComponent } from './component/product/product.component';
import { MomentModule } from 'ngx-moment';
import { ProductsComponent } from './component/product/products.component';
import { GroupByCategory } from './component/product/group-by-search-type';
import { ProductConfirmComponent } from './component/shop/confirm/product-confirm.component';
import { CartComponent } from './component/shop/cart/cart.component';
import { CheckoutComponent } from './component/shop/checkout/checkout.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { PaymentComponent } from './component/shop/payment/payment.component';
import { OrderHistoryComponent } from './component/order/order-history/order-history.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventComponent } from './component/events/event.component';
import { NotMobileComponent } from './component/not-mobile/not-mobile.component';
import { MobileGuard } from './class/mobile-guard/mobile-guard';
import { WebcamModule } from 'ngx-webcam';
import { PlaceComponent } from './component/place/place.component';
import { DescriptionPipe } from './class/pipe/description-pipe';
import { AboutUsComponent } from './component/about-us/about-us.component';
import { WebpPipe } from './class/pipe/webp-pipe';
import { NgImageSliderModule } from 'ng-image-slider';
import { LeaderboardComponent } from './component/leaderboard/leaderboard.component';
import { BenefitsComponent } from './component/benefits/benefits.component';
import { BenefitComponent } from './component/benefits/benefit.component';

import { AngularCropperjsModule } from 'angular-cropperjs';
import { AlertComponent } from './component/alert/alert.component';
import { PhotoAlbumsComponent } from './component/photo-albums/photo-albums.component';
import { PhotoAlbumComponent } from './component/photo-albums/photo-album/photo-album.component';

import { ForumComponent } from './component/forum/forum.component';
import { ChooseReplyComponent } from './component/forum/choose-reply/choose-reply.component';
import { NewPostComponent } from './component/forum/new-post/new-post.component';
import { CtlQtyDialogComponent } from './component/ctl-qty-dialog/ctl-qty-dialog.component';
import { MatTableModule } from '@angular/material/table';
import { CompetitionsComponent } from './component/competitions/competitions.component';
import { CompetitionComponent } from './component/competitions/competition/competition.component';
import { MembersComponent } from './component/members/members.component';
import { MemberComponent } from './component/members/member/member.component';
import { LinksComponent } from './component/links/links.component';
import { PlacesComponent } from './component/place/places.component';
import { MenusComponent } from './component/restaurant/menus/menus.component';
import { MenuComponent } from './component/restaurant/menu/menu.component';
import { MenusByCategory } from './component/restaurant/menus/menu-by-search-type';
import { TableNamePipe } from './class/pipe/table-name-pipe';
import { MenuConfirmComponent } from './component/restaurant/confirm/menu-confirm.component';
import { MealComponent } from './component/restaurant/meal/meal.component';
import { RelatedProductComponent } from './component/restaurant/related/related-product.component';
import { MealCheckoutComponent } from './component/restaurant/checkout/checkout.component';
import { AlcoholComponent } from './component/restaurant/alcohol/alcohol.component';
import { MealPaymentComponent } from './component/restaurant/payment/payment.component';
import { LinkComponent } from './component/links/link.component';
import { EverstreamComponent } from './component/everstream/everstream.component';
import { EverstreamsComponent } from './component/everstream/everstreams.component';
import { DatefmtPipe } from './class/pipe/datefmt-pipe';
import { VjsPlayerComponent } from './component/everstream/video_js';
import { SwiperModule } from 'swiper/angular';
import { CPSURLComponent } from './component/booking/cpsurl.component';
import { QRCodeModule } from 'angularx-qrcode';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

const initializer = (pwaService: PwaService) => () => pwaService.initPwaPrompt();
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    NewsComponent,
    AdsComponent,
    EventsComponent,
    NewComponent,
    DisclaimerComponent,
    DialogComponent,
    ToolbarComponent,
    AccountComponent,
    SignupComponent,
    PromptComponent,
    FirebaseComponent,
    NotificationComponent,
    SearchComponent,
    GroupBySearchType,
    GroupByCategory,
    ProductComponent,
    ProductsComponent,
    ProductConfirmComponent,
    CartComponent,
    CheckoutComponent,
    PaymentComponent,
    OrderHistoryComponent,
    EventComponent,
    NotMobileComponent,
    AboutUsComponent,
    PlaceComponent,
    DescriptionPipe,
    WebpPipe,
    LeaderboardComponent,
    BenefitsComponent,
    BenefitComponent,
    AlertComponent,
    SignupComponent,
    PhotoAlbumsComponent,
    PhotoAlbumComponent,
    ForumComponent,
    ChooseReplyComponent,
    NewPostComponent,
    CtlQtyDialogComponent,
    CompetitionsComponent,
    CompetitionComponent,
    MembersComponent,
    MemberComponent,
    LinksComponent,
    LinkComponent,
    PlacesComponent,
    MenusComponent,
    MenuComponent,
    MenusByCategory,
    TableNamePipe,
    DatefmtPipe,
    MenuConfirmComponent,
    MealComponent,
    RelatedProductComponent,
    MealCheckoutComponent,
    AlcoholComponent,
    MealPaymentComponent,
    EverstreamComponent,
    EverstreamsComponent,
    VjsPlayerComponent,
    CPSURLComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    ServiceWorkerModule.register('combined-sw.js', { enabled: environment.production }),
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatIconModule,
    MatCardModule,
    MatListModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatToolbarModule,
    MatInputModule,
    MatGridListModule,
    MatDialogModule,
    MatBottomSheetModule,
    MatSnackBarModule,
    MatSelectModule,
    MatMenuModule,
    MatBadgeModule,
    MatRadioModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatExpansionModule,
    MatSlideToggleModule,
    NgImageSliderModule,
    AngularCropperjsModule,
    AngularFireModule.initializeApp(environment.firebase),
    MomentModule.forRoot(),
    SwiperModule,
    WebcamModule,
    MatTableModule,
    MatDialogModule,
    SwiperModule,
    QRCodeModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
  ],
  exports: [
    GroupBySearchType,
    DescriptionPipe,
    WebpPipe,
    TableNamePipe,
    DatefmtPipe,
  ],
  providers: [
    Authguard,
    MobileGuard,
    AsyncPipe,
    DecimalPipe,
    FirebaseService,
    {provide: APP_INITIALIZER, useFactory: initializer, deps: [PwaService], multi: true},
    {provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: ['DD.MM.YYYY'],
      },
      display: {
        dateInput: 'DD.MM.YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'DD.MM.YYYY',
        monthYearA11yLabel: 'MMMM YYYY',
      },
    }},
  ],
  bootstrap: [AppComponent],
  entryComponents: [DialogComponent],
})
export class AppModule { }
